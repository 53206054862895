import { Alert, AlertTitle, CircularProgress, Container, TextField } from "@mui/material"
import axios from "axios";
import { useState } from "react";
import { RESERVATION } from "../../constants/urls";
import {  useNavigate } from "react-router-dom";

const Table = () => {

    const [loading, setLoading] = useState(false)
    const [tablenum, setTablenum] = useState('')
    const [showerror, setShowError] = useState(false)
    const [erroralert, showErrorAlert] = useState('');
    const navigate = useNavigate();
    const onchange =(e) =>{
        if (e.target.value > 0){
            setTablenum(e.target.value)
        }
    }
    const ReservationTable = async () => {
        setLoading(true)
        const data = {
            table_number: tablenum,
            latitude: String(47.4943629),
            longitude: String(19.0588622)
        }
        await axios.post(RESERVATION, data)
            .then((response) => {
                localStorage.setItem("token", response.data.data.token)
                setShowError(false)
               window.location.reload()
            })
            .catch((error) => {
                setShowError(true)
                showErrorAlert('please enter a valid table number')
            });
        setLoading(false)
    }
    // get current user location
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error);
    } else {
        console.log("Geolocation not supported");
    }

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    }
    function error() {
        console.log("Unable to retrieve your location");
    }


    return (

                <Container maxWidth="sm" className="table-screen text-center bg-white" >
                    {showerror &&
                        <Alert severity="error" className='mb-4'>
                            <AlertTitle>Error {erroralert} </AlertTitle>
                        </Alert>
                    }
                    <h4 className="fw-bold" style={{ color: '#FAA61A' }}>Table Number</h4>
                    <h6 className="mb-5">The app will require access your table number</h6>
                    <TextField id="standard-basic" label="Table Number" variant="standard" value={tablenum}  
                        onChange={onchange}
                        type="number" className="mb-5" />
                      
                    <Container maxWidth="sm" >
                        {loading ? <div><CircularProgress style={{ color: '#FAA61A' }} /> <p style={{ color: '#FAA61A' }}>Please, wait ...</p></div> :
                            <button onClick={ReservationTable}>Submit</button>
                        }
                    </Container>
                </Container>
                
    )
}

export default Table
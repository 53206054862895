import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { CURRENCY } from "../../constants/urls";
import { useContext, useState, useEffect } from "react";
import axios from "axios";
import OrderContext from "../../store/OrderContext";
import { useTranslation } from "react-i18next";

const Currency = (props) => {
    const { t } = useTranslation();
    const orderContext = useContext(OrderContext);
    const token = localStorage.getItem('token');
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState([]);

    const handleChange = (value) => {
        orderContext.currencyStateFun(value);
        localStorage.removeItem("new");
        localStorage.removeItem('total');
        orderContext.reset();
        props.setReload(!props.reload);
    };

    const FetchCurrency = async () => {
        setLoading(true);
        await axios
            .get(CURRENCY, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setList(response.data.data);
                    if (!orderContext.currencyState || !response.data.data.some(item => item.currency === orderContext.currencyState)) {
                        orderContext.currencyStateFun("IQD");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        setLoading(false);
    };

    useEffect(() => {
        FetchCurrency();
    }, []);

    return (
        <div className="filter-section">
            <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="demo-simple-select-filled-label">{t("currency_title")}</InputLabel>
                <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={orderContext.currencyState}
                    onChange={(e) => handleChange(e.target.value)}>
                    {list.length > 0 ?
                        list.map((item) => (
                            <MenuItem value={item.currency} key={item.id}>{item.currency}</MenuItem>
                        ))
                        : <Typography variant="p" className="text-center" style={{ fontSize: '14px', padding: '5px' }} sx={{ mb: 5 }}>
                            No currency yet
                        </Typography>}
                </Select>
            </FormControl>
        </div>
    );
};

export default Currency;
